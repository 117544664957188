<template>
    <Login v-if="!jwt || tokenIsExpired" />
    <PassageList
        v-if="jwt && selectedPassage === null"
        :selected-difficulty-level="selectedDifficultyLevel"
        @passage-selection="handlePassageSelection"
        @difficulty-selection="handleDifficultySelection"
    />
    <PracticeArea
        v-if="jwt && selectedPassage"
        :passage-id="selectedPassage"
        :difficulty-level="selectedDifficultyLevel"
        :word-percentage="wordPercentage"
        @practice-completion="handlePracticeCompletion"
    />
</template>

<script>
import Login from './components/Login.vue';
import PassageList from './components/PassageList.vue';
import PracticeArea from './components/PracticeArea.vue';

export default {
    name: 'App',
    components: {
        Login,
        PassageList,
        PracticeArea
    },
    data() {
        return {
            jwt: localStorage.getItem("jwt"),
            difficultLevels: null,
            selectedPassage: null,
            selectedDifficultyLevel: 1,
            wordPercentage: 100,
            currentPage: '',
            tokenExpiration: 0,
            tokenIsExpired: false
        }
    },
    methods: {
        handlePassageSelection(passageId) {
            this.selectedPassage = passageId
            console.log(passageId)
        },
        handleDifficultySelection(difficultLevel) {
            this.selectedDifficultyLevel = difficultLevel.Id
            this.wordPercentage = difficultLevel.WordsShownPercentage
        },
        handlePracticeCompletion() {
            this.selectedPassage = null
        },
        getTokenExpiration(token) {
            let base64Url = token.split('.')[1];
            let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            let jsonPayload = atob(base64)
            return JSON.parse(jsonPayload).exp;
        },
        checkTokenExpiration(tokenExpiration) {
            return Math.floor(new Date() / 1000) > (tokenExpiration - 86400)
        }
    },
    created() {
        this.tokenExpiration = this.getTokenExpiration(this.jwt)
        this.tokenIsExpired = this.checkTokenExpiration(this.tokenExpiration)
    }
};
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    margin-top: 60px;
}
</style>
