<template>
    <h3>{{ passageReference }}</h3>
    <input type="text" ref="input" @keyup="checkKey" @keydown="(event) => event.preventDefault()" />
    <button v-show="!passageFinished" @click="practiceAgain()">Restart</button>
    <button v-show="passageFinished" @click="practiceAgain">Practice again</button>
    <button v-show="passageFinished" @click="this.$emit('practiceCompletion')">Back to passage list</button>
    <div><span v-for="word in words" :class="[word.visibility]">{{ word.text }}&nbsp;</span></div>
    <div>{{ textToDisplay }}</div>
</template>

<script>
import axios from "axios";

export default {
    name: 'PracticeArea',
    props: {
        passageId: Number,
        difficultyLevel: Number,
        wordPercentage: Number
    },
    data() {
        let now = new Date()
        return {
            date: now.toISOString().substring(0, 10),
            startTime: now.toISOString().substring(11, 19),
            endTime: "",
            wordIndex: 0,
            errorCount: 0,
            passageReference: "",
            words: null,
            textToDisplay: "",
            difficultLevels: null,
            passageFinished: false
        }
    },
    methods: {
        checkKey(event) {
            if (!event.code.startsWith('Key')) return

            let letter = event.code.split('Key')[1].toLowerCase()
            if(this.words[this.wordIndex].text.toLowerCase().startsWith(letter)) {
                this.words[this.wordIndex].visibility = 'revealed'
                this.wordIndex++
            } else {
                this.errorCount++ 
                this.words[this.wordIndex].visibility = 'missed'
                this.wordIndex++
            }

            if(this.wordIndex === this.words.length) {
                let now = new Date()
                this.endTime = now.toISOString().substring(11, 19),
                event.target.classList.add('display-none')
                let accuracy = (this.words.length - this.errorCount) / this.words.length * 100
                this.textToDisplay += `${accuracy.toFixed(1)}% Accurate`
                this.recordAttempt(accuracy.toFixed(1))
            }
        },
        recordAttempt(accuracy) {
            axios.post(`https://api.bible-memory.josiahmaddux.com/practice-attempt`, {
                PassageId: Number(this.passageId),
                AttemptDate: this.date,
                AttemptStartTime: this.startTime,
                AttemptEndTime: this.endTime,
                Accuracy: accuracy,
                DifficultyLevel: Number(this.difficultyLevel)
            },
            {
                headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` }
            }).then(resp => {
                this.passageFinished = true
            })
        },
        getRandomVisibility() {
            return Math.floor(Math.random() * 100) + 1 <= this.wordPercentage ? 'hinted' : 'hidden'
        },
        loadPassage() {
            axios.get(`https://api.bible-memory.josiahmaddux.com/passage/${this.passageId}`).then(resp => {
                this.passageReference = resp.data.Reference
                this.passageText = resp.data.PassageText
                this.words = this.passageText.split(' ').map((word => { return {text: word, visibility: this.getRandomVisibility()} }))
            })
        },
        loadDifficultyLevels() {
            this.difficultLevels = JSON.parse(localStorage.getItem('difficultyLevels'))
            if (!this.difficultLevels) {
                axios.get('https://api.bible-memory.josiahmaddux.com/difficulty-levels').then(resp => {
                    this.difficultLevels = resp.data
                    localStorage.setItem('difficultyLevels', JSON.stringify(resp.data))
                })
            }
        },
        practiceAgain() {
            let now = new Date()
            this.date = now.toISOString().substring(0, 10),
            this.startTime = now.toISOString().substring(11, 19),
            this.endTime = "",
            this.wordIndex = 0,
            this.errorCount = 0,
            this.passageReference = "",
            this.words = null,
            this.textToDisplay = "",
            this.difficultLevels = null,
            this.passageFinished = false
            this.loadPassage()
            this.$refs["input"].classList.remove('display-none')
        }
    },
    created() {
        this.loadPassage()
        this.loadDifficultyLevels()
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .hidden {
        visibility: hidden;
    }

    .hinted {
        color: lightgray;
    }

    .missed {
        color: red;
    }

    .revealed {
        color: default;
    }

    .display-none {
        display: none;
    }

    button {
        padding: 5px;
        margin: 5px;
        border: solid 1px;
        border-radius: 4px;
    }
</style>
