<template>
    <select @change="handleDifficultySelection" :value="selectedDifficultyLevel">
        <option v-for="level in difficultyLevels" :value="level.Id">{{ level.DifficultyLevel }}</option>
    </select>
    <table>
        <tr>
            <th>Passage Reference</th>
            <th>Times Practiced</th>
            <th>Memorized?</th>
            <th>Last Practiced</th>
            <th>Average Score</th>
        </tr>
        <tr v-for="passage in passages">
            <td><a href="#" :data-passage-id="passage.Id" @click="handlePassageSelection">{{ passage.Reference }}</a></td>
            <td>{{ passage.TimesPracticed }}</td>
            <td>{{ passage.Memorized }}</td>
            <td>{{ passage.LastPracticedDate }} {{ passage.LastPracticedTime }}</td>
            <td>{{ passage.AverageScore }}</td>
        </tr>
    </table>
</template>

<script>
import axios from "axios";

export default {
    name: 'PassageList',
    props: {
        selectedDifficultyLevel: Number
    },
    data() {
        return {
            passages: null,
            difficultyLevels: null
        }
    },
    methods: {
        handlePassageSelection(event) {
            event.preventDefault()
            console.log(event.target)
            this.$emit('passageSelection', Number(event.target.dataset.passageId))
        },
        handleDifficultySelection(event) {
            let difficultyLevelId = Number(event.target.value)
            let difficultyLevel = this.difficultyLevels.find(x => x.Id === difficultyLevelId)
            this.$emit('difficultySelection', difficultyLevel)
        },
        loadDifficultyLevels() {
            this.difficultyLevels = JSON.parse(localStorage.getItem('difficultyLevels'))
            if (!this.difficultyLevels) {
                axios.get('https://api.bible-memory.josiahmaddux.com/difficulty-levels').then(resp => {
                    this.difficultyLevels = resp.data
                    localStorage.setItem('difficultyLevels', JSON.stringify(resp.data))
                })
            }
        }
    },
    created() {
        this.loadDifficultyLevels()
        axios.get('https://api.bible-memory.josiahmaddux.com/passages', {headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` }}).then(resp => {
            console.log(resp)
            this.passages = resp.data
        })
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
select {
    border: solid 1px;
    border-radius: 4px;
    margin: 10px 0;
    padding: 5px;
}


th {
    background-color: #4159c5;
    color: #fff;
}

tr {
    border: 1px solid;
}

table {
    border-collapse: collapse;
    
}

th, td {
    padding: 5px 10px;
}

</style>
