<template>
    <form v-on:submit="login" autocomplete="off">
        <h1>Login</h1>
        <div>
            <div>
                <label for="username">Username: </label>
                <input id="username" name="username" type="text" v-model="username" />
            </div>
            <div>
                <label for="password">Password: </label>
                <input id="password" name="password" type="password" v-model="password" />
            </div>
        </div>
        <button type="submit">Login</button>
    </form>
</template>

<script>
import axios from "axios";

export default {
    name: 'Login',
    props: {
        msg: String,
    },
    data() {
        return {
            username: "",
            password: ""
        }
    },
    methods: {
        login(event) {
            event.preventDefault()
            console.log(this.username)
            axios.post('https://api.bible-memory.josiahmaddux.com/login', { username: this.username, password: this.password })
                .then(resp => {
                    console.log(resp)
                    localStorage.setItem("jwt", resp.data.token)
                })
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1 {
    text-align: center;
}

input {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    box-sizing: border-box;
    border: 2px solid gray;
    border-radius: 4px;
}

form {
    margin: auto;
    width: 80%;
    max-width: 500px;
}

button {
    width: 100%;
    background-color: #04AA6D;
    border: none;
    color: white;
    padding: 16px 32px;
    text-decoration: none;
    margin: 4px 2px;
    cursor: pointer;
}
</style>
